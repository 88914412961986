<template>
  <div class="page1">
    <!--面包屑-->
    <div class="breadcrumb">
      <div style='text-align:right'>
        <el-button @click='$router.go(-1)'
                   type='primary'>返回</el-button>
      </div>
    </div>
    <!--主体内容-->
    <div class="content">
      <el-tabs v-model="activeName2"
               type="card"
               @tab-click="handleClick">
        <!-- <el-tab-pane v-if='authority.tabs["monitor"]'
                     label="监控设备管理"
                     name="first">
          <monitor-equip :authority='authority.tabs["monitor"]'
                         ref='monitor'></monitor-equip>
        </el-tab-pane> -->
        <el-tab-pane v-if='authority.tabs["ms"]'
                     label="MS管理"
                     name="six">
          <ms-control :authority='authority.tabs["ms"]'
                      ref='collector'></ms-control>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
import msControl from "./parkSetChilds/MS/index"
// import monitorEquip from "./parkSetChilds/monitorEquip/index"
export default {
  name: 'hello',
  data() {
    return {
      authority: this.$setAuthority('parkConfig'),
      activeName2: 'six'
    }
  },
  watch: {
    authority: {
      handler(ne, ol) {
        this.$nextTick(() => {
          let arr = Object.keys(ne.tabs).filter(v => {
            return v == '2010000500' || v == '2010000700'
          })
          this.$refs.collector.init();
          // switch (arr[0]) {
          //   case "2010000500":
          //     this.$refs.monitor.init();
          //     break;
          //   case "2010000700":
          //     this.activeName2 = 'six';
          //     this.$refs.collector.init();
          //     break;
          //   default:
          // }
        })
      },
      immediate: true
    }
  },
  methods: {
    handleClick(tab, event) {
      switch (tab.name) {
        case 'first':
          this.$refs.monitor.init();
          break;
        case 'second':
          this.$refs.equipment.init();
          break;
        case 'third':
          this.$refs.pole.init();
          break;
        case 'fourth':
          this.$refs.charge.init();
          break;
        case 'five':
          this.$refs.monitor.init();
          break;
        case 'six':
          this.$refs.collector.init();
          break;
        default:
      }
    }
  },
  components: {
    // monitorEquip,
    msControl
  },
  created() {
  },
  mounted() {
    if (!this.authority.tabs["monitor"] && this.authority.tabs["ms"]) {
      this.activeName2 = 'six'
    }
    // this.$refs.monitor.init();
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.content
  background #FFFFFF
  overflow hidden
  .pagerWrapper
    text-align right
    margin-top 28px
    font-size 12px
    float right
    .export
      font-size 12px
      .iconfont
        margin-right 0
  .btnWrapper
    text-align left
    margin-top 28px
    float left
.detail
  width 61.4%
  height 82.9%
  position absolute
  top 8.6%
  left 20.1%
  background #FFFFFF
  box-shadow 0 0 4px 1px rgba(128, 145, 165, 0.3)
  border-radius 3px
  z-index 1000
  box-sizing border-box
  padding 20px 0
  .title
    position absolute
    background #3F4A56
    border-radius 3px 3px 0 0
    color #fff
    width 100%
    top 0
    left 0
    height 40px
    line-height 40px
    font-size 14px
    padding 0 12px
    box-sizing inherit
    z-index 100
    .el-icon-close
      float right
      margin-top 14px
  .info
    margin-top 20px
    padding 2.1%
    height 100%
    overflow auto
    box-sizing inherit
.mask
  background rgba(49, 53, 65, 0.6)
  position fixed
  top 0
  bottom 0
  left 0
  right 0
  z-index 999
.transfer
  margin 0 auto
.button
  margin-bottom 15px
  text-align right
.ruleDetail
  width 500px
  height 150px
  overflow auto
  border 1px solid #bfcbd9
  border-radius 5px
  color #1f2d3d
.feeDis
  margin-left 20px
.berth-sort
  margin-top 10px
</style>
<style>
.el-table .success-row {
  background: #f0f9eb !important;
}
</style>
