<template>
    <div>
        <el-dialog
            title="添加MS账号"
            :visible.sync="dialogVisible"
            :close-on-click-modal='false'
            width="500px"
            :show-close='false'
            custom-class='dialog'
            >
            <el-form ref="form" :model="formInline" :rules='rules' label-width="120px" style='padding:0'>
                <el-form-item label="MS账号" prop='username'>
                      <el-input v-model="formInline.username" class='formWidth'></el-input>
                </el-form-item>
                <el-form-item label="密码" prop='pwd'>
                      <el-input v-model="formInline.pwd" class='formWidth'></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer">
                <el-button type="primary" @click="save">{{ $t('button.preservation') }}</el-button>
                <el-button @click="dialogVisible = false">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
export default {
   data () {
       let checkName = (rule, value, callback) => {
           if (/^[A-z0-9_]+$/.test(value)) {
               callback();
           } else {
               callback(new Error('账户名支持字母和数字！'));
           }
       }
       let checkCode = (rule, value, callback) => {
           if (/^[A-z0-9]+$/.test(value)) {
               callback()
           } else {
               callback(new Error('密码支持大小写字母及数字！'))
           }
       }
       return {
           dialogVisible: false,
           formInline: {
               username: '',
               pwd: ''
           },
           rules: {
               username: [{
                    required: true,
                    message: '必填',
                    trigger: 'blur'
               }, { validator: checkName, trigger: 'blur' }],
               pwd: [{
                    required: true,
                    message: '必填',
                    trigger: 'blur'
               }, { validator: checkCode, trigger: 'blur' }]
           }
       }
   },
   methods: {
       open () {
           this.formInline.userName = '';
           this.formInline.pwd = '';
           this.dialogVisible = true;
       },
       save () {
          this.$refs.form.validate((valid) => {
              if (valid) {
                  this.$axios.post('/acb/2.0/create', {
                data: {
                    parkId: this.$route.query.parkId,
                    ...this.formInline
                    }
            }).then(res => {
            if (res.state == 0) {
                if (res.value) {
                this.$emit('searchData');
                this.dialogVisible = false;
                }
            } else {
                this.$alert(res.desc, this.$t('pop_up.Tips'), {
                    confirmButtonText: this.$t('pop_up.Determine')
                    })
            }
            })
              }
          })
       }
          // 搜索
    //   searchData () {
    //     this.tableData = [];
    //     this.loading = true;
    //      this.$axios.get('/acb/2.0/pdaManager/queryNoInCurrentPark/' + this.$route.query.parkId, {
    //       data: {
    //         page: 0,
    //         pageSize: 0
    //       }
    //     }).then(res => {
    //       this.loading = false;
    //       if (res.state == 0) {
    //         if (res.value) {
    //            this.data2 = res.value;
    //         }
    //       } else {
    //         this.$alert(res.desc, this.$t('pop_up.Tips'), {
    //               confirmButtonText: this.$t('pop_up.Determine')
    //               })
    //       }
    //     })
    //   }
   },
   created () {
   }
}
</script>
<style>
    .formWidth{
        width:221.5px;
    }

</style>

