<template>
    <div>
        <el-dialog
            title="编辑MS账号"
            :close-on-click-modal='false'
            :visible.sync="dialogVisible"
            width="500px"
            :show-close='false'
            custom-class='dialog'
            >
            <el-form ref="form" :model="formInline" :rules='rules' label-width="120px" style='padding:0'>
                <el-form-item label="MS账号" prop='username'>
                      <el-input v-model="formInline.username" class='formWidth'></el-input>
                </el-form-item>
                <el-form-item label="密码" prop='pwd'>
                      <el-input v-model="formInline.pwd" class='formWidth'></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer">
                <el-button type="primary" @click="save">{{ $t('button.preservation') }}</el-button>
                <el-button @click="dialogVisible = false">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
export default {
   data () {
       let checkCode = (rule, value, callback) => {
           if (/^[A-z0-9]+$/.test(value)) {
               callback();
           } else {
               callback(new Error('密码支持大小写字母及数字！'));
           }
       }
       let checkName = (rule, value, callback) => {
           if (/^[A-z0-9_]+$/.test(value)) {
               callback();
           } else {
               callback(new Error('账户名支持字母和数字！'));
           }
       }
       return {
           closedParkUserId: '',
           dialogVisible: false,
           formInline: {
               username: '',
               pwd: ''
           },
           rules: {
               username: [{
                    required: true,
                    message: '必填',
                    trigger: 'blur'
               }, { validator: checkName, trigger: 'blur' }],
               pwd: [{
                    required: true,
                    message: '必填',
                    trigger: 'blur'
               }, { validator: checkCode, trigger: 'blur' }]
           }
       }
   },
   methods: {
       open () {
           this.formInline.username = '';
           this.formInline.pwd = '';
           this.dialogVisible = true;
       },
       save () {
          this.$refs.form.validate((valid) => {
              if (valid) {
                  this.$axios.post('/acb/2.0/update', {
                data: {
                    parkId: this.$route.query.parkId,
                    closedParkUserId: this.closedParkUserId,
                    ...this.formInline
                    }
            }).then(res => {
            if (res.state == 0) {
                if (res.value) {
                this.$emit('searchData');
                this.dialogVisible = false;
                }
            } else {
                this.$alert(res.desc, this.$t('pop_up.Tips'), {
                    confirmButtonText: this.$t('pop_up.Determine')
                    })
            }
            })
              }
          })
       },
      getDetail ({closedParkUserId}) {
          this.closedParkUserId = closedParkUserId;
        this.tableData = [];
        this.loading = true;
         this.$axios.get('/acb/2.0/getById', {
             data: {
                 closedParkUserId
             }
         }).then(res => {
          if (res.state == 0) {
            if (res.value) {
               this.formInline.username = res.value.username;
               this.formInline.pwd = res.value.pwd;
            }
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine')
                  })
          }
        })
      }
   },
   created () {
   }
}
</script>
<style>
    .formWidth{
        width:221.5px;
    }

</style>

