var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "添加MS账号",
            visible: _vm.dialogVisible,
            "close-on-click-modal": false,
            width: "500px",
            "show-close": false,
            "custom-class": "dialog",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticStyle: { padding: "0" },
              attrs: {
                model: _vm.formInline,
                rules: _vm.rules,
                "label-width": "120px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "MS账号", prop: "username" } },
                [
                  _c("el-input", {
                    staticClass: "formWidth",
                    model: {
                      value: _vm.formInline.username,
                      callback: function ($$v) {
                        _vm.$set(_vm.formInline, "username", $$v)
                      },
                      expression: "formInline.username",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "密码", prop: "pwd" } },
                [
                  _c("el-input", {
                    staticClass: "formWidth",
                    model: {
                      value: _vm.formInline.pwd,
                      callback: function ($$v) {
                        _vm.$set(_vm.formInline, "pwd", $$v)
                      },
                      expression: "formInline.pwd",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.save } },
                [_vm._v(_vm._s(_vm.$t("button.preservation")))]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }